<template>
    <div class="box" :style="{height: boxHeight}">
        <div class="boxtop">
            <div class="topimg" @mouseenter="isEnter=true" @mouseleave="isEnter=false">
                <div class="topBox"><p>Hello Wrold</p></div> 
                <img :class="{img:isEnter,img2:!isEnter}" ref="img" src="~images/headPortrait.gif" alt="">
            </div>
            <div class="title"><p>欢迎使用{{companyType}}</p><p class="second">{{companyName}}</p></div>
        </div>
        <div class="bottomImg">
            <img class="bimg"  src="~assets/images/character.gif">
        </div>
    </div>
     <!-- welcome communityList -->
</template>


<script>
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
    data(){
        return{
            no: false,
            url1:'~images/character.gif',
            url2:'https://pic-static.fangzhizun.com/images/headPortrait.gif',
            isEnter:false,
            companyName:sessionStorage.getItem('companyName'),
            companyType:sessionStorage.getItem('companyType'),
            boxHeight: `${window.innerHeight - 95}px`
        }
    },
    computed:{
        ...mapState(['test'])
    },
    created(){
        console.log(this.$route.query.token)
        // 外传token
        if(this.$route.query && this.$route.query.token) {
            sessionStorage.setItem('spId',1)
            sessionStorage.setItem('way',0)
            sessionStorage.setItem('token',this.$route.query.token)
        }
        this.getNameList()
        this.getSetList()
        this.getJcuGroupType()
        this.getCityList()
        this.getCurrentUser()
        this.getBudgetList();
        // -----------------------------
        // this.getEquipment();
        // this.getStatusBus()
        // -----------------------------
        console.log(this,'98')

    },
    mounted(){
        // 监听页面宽度改变
        window.onresize = () => {
            return (() => {
                this.boxHeight = `${window.innerHeight - 95}px`
            })()
        }
    },
    methods: {
        ...mapActions(['getNameList','getSetList','getJcuGroupType','getCityList','getCurrentUser','getBudgetList']),
    },
    watch:{
        no: {
            handler:function(val) {
                console.log(val)
            },
            // 立即执行watch
            immediate:true
        }
    }
}
</script>
<style lang="scss" scoped>

.ko{
    height: 100vh;
}
    html,body{
        padding:0;
        margin:0;
    }
    .box{
        background: #e3e3e3;
        padding:0 ;
        margin: 0 ;
        // height: 100vh;
        // height: 850px;
        // box-sizing: border-box;
        display: flex;
        flex-direction: column;
        // text-align: center;
        color: #666;
        font-size: 19px;
        font-weight: 600;
        .boxtop{
            width: 100%;
            // flex:1;
            height:200px;
            display: flex;
            margin-top:48px;
            // justify-content:center;
            .title{
                font-size:35px;
                  color:#212121;
                // line-height: 50px;
                margin:0 0 0 40px;
                .second{
                  font-size:20px; 
                }
            }
        .topimg{
            z-index: 12;
            position: relative;
            box-sizing: border-box;
            height: 150px;
            width: 150px;
            font-size: 14px;
            margin-left:75px;
            margin-top: 20px;
            border-radius: 50%;
            text-align: center;
            .topBox{
                height: 140px;
                width: 140px;
                z-index: 12;
                position:absolute;
                top: 0;
                left: 0;
                // line-height: 50px;
                border-radius: 50%;
                border:5px solid #aaa;
                p{
                    margin-top:50px;
                }
            }
            .img2{
               display: block;
                height: 100%;
                z-index: 12;
                width: 100%;
                border-radius: 50%;
                position:absolute;
                top: 0;
                left: 0;
                // box-shadow: 0 1px 3px rgba(0,0,0,.2); 
            }
            .img{
                z-index: 12;
                display: block;
                height: 100%;
                width: 100%;
                border-radius: 50%;
                position:absolute;
                top: 0;
                left: 0;
                // box-shadow: 0 1px 3px rgba(0,0,0,.2);
                // -webkit-transform-origin: 95% 40%;
                // -ms-transform-origin: 95% 40%;
                // -o-transform-origin: 95% 40%;
                // -moz-transform-origin: 95% 40%;
                transform-origin: 95% 40%;
                // -ms-transform: rotate(-100deg);
                // -webkit-transform: rotate(-100deg);
                // -o-transform: rotate(-100deg);
                // -moz-transform: rotate(-100deg);
                transform: rotate(-100deg);
                // -webkit-transition: all .5s ease-in-out;
                // -ms-transition: all .5s ease-in-out;
                // -o-transition: all .5s ease-in-out;
                // -moz-transition: all .5s ease-in-out;
                transition: all .5s linear;

             
            
            }
        }}
        .bottomImg{
            width: 100%;
            background: #e3e3e3;
            flex-grow:2;
            .bimg{
                display: block;
                width: 45%;
                margin: 0 auto;
            }
        }
    }
</style>